.container {
    padding: 2rem;
}

.card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    margin-bottom: 2rem;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

input[type="text"], select {
    width: 100%;
    padding: 0.75rem;
    margin: 0.5rem 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

button {
    background-color: transparent;
    color: #333;
    border: none;
    cursor: pointer;
    font-size: 1.2rem;
    margin-right: 0.5rem;
}

button:hover {
    color: #000;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1rem;
}

th, td {
    padding: 0.75rem;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

td input, td select {
    width: 100%;
}

h3 {
    margin-top: 2rem;
}

.select-values {
    margin-top: 1rem;
}

.select-values ul {
    list-style: none;
    padding: 0;
}

.select-values ul li {
    background-color: #f2f2f2;
    margin-bottom: 0.5rem;
    padding: 0.5rem;
    border-radius: 4px;
}
