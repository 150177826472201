/* frontend/src/components/styles/UnitModal.css */

.modal {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.units-list ul {
    list-style-type: none;
    padding: 0;
}

.units-list li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
}

.add-unit {
    margin-top: 20px;
}

.add-unit select {
    margin-right: 10px;
}

.add-unit label {
    margin-left: 10px;
}

.add-unit button {
    margin-left: 10px;
}
