.spinner {
  width: 18px;
  height: 18px;
  border: 2px solid #fff;
  border-top: 2px solid #00aaff;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
  margin: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Пример стиля для .add-button.uploading - перекрашиваем, убираем hover */
.add-button.uploading {
  background-color: #bbb;
  cursor: not-allowed;
}
