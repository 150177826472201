.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
    background-color: #fefefe;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    position: relative;
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.select-values ul {
    list-style-type: none;
    padding: 0;
}

.select-values li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
}

.add-select-value {
    margin-top: 20px;
}

.add-select-value input[type="text"] {
    width: calc(100% - 110px);
    padding: 5px;
    margin-right: 10px;
}

.add-select-value button {
    padding: 5px 10px;
}
