/* App.css */

body {
    font-family: 'Open Sans', sans-serif;
    background-color: var(--background-color);
    color: var(--text-color);
}

button {
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 4px;
    padding: 0.75rem 1.5rem;
    cursor: pointer;
    font-size: 1rem;
}

button:hover {
    background-color: var(--secondary-color);
}

input[type="text"],
input[type="password"],
textarea {
    width: 100%;
    padding: 0.75rem;
    margin: 0.5rem 0;
    border: 1px solid #ccc;
    border-radius: 4px;
}
