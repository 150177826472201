/* frontend/src/components/styles/ReferenceModal.css */

.modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.5);
}

.modal-content {
    background-color: #fff;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-height: 80vh; /* Limiting the height of the modal */
    overflow-y: auto; /* Adding scroll to the modal content */
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.tabs {
    display: flex;
    margin-bottom: 10px;
}

.tabs button {
    flex: 1;
    padding: 10px;
    cursor: pointer;
    border: none;
    background-color: #f1f1f1;
}

.tabs button.active {
    background-color: #ccc;
    font-weight: bold;
}

.reference-value-item {
    border-bottom: 1px solid #ccc;
    padding: 10px 0;
    margin-bottom: 10px;
}

.reference-value-item:last-child {
    border-bottom: none;
    margin-bottom: 0;
}

.reference-value-actions {
    margin-top: 10px;
}

.reference-value-actions button {
    margin-right: 10px;
}

.add-reference-value {
    margin-top: 20px;
}
