.container {
    padding: 2rem;
}

h1 {
    margin-bottom: 1.5rem;
}

.card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    margin-bottom: 2rem;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

input[type="text"], select {
    width: 100%;
    padding: 0.75rem;
    margin: 0.5rem 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

button {
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 0.75rem 1rem;
    cursor: pointer;
    font-size: 1rem;
    margin-right: 0.5rem;
    margin-top: 0.5rem;
}

button:hover {
    background-color: #45a049;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1rem;
}

th, td {
    padding: 0.75rem;
    border: 1px solid #ddd;
    text-align: left;
}

th {
    background-color: #f2f2f2;
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.pagination button {
    background-color: #f2f2f2;
    border: 1px solid #ddd;
    padding: 0.5rem 1rem;
    margin: 0 0.25rem;
    cursor: pointer;
}

.pagination button.active {
    background-color: #4caf50;
    color: white;
}

.pagination button:hover:not(.active) {
    background-color: #ddd;
}

.highlight-wait { background-color: #FFDAB9; }