/* frontend/src/components/styles/ProfileWizard.css */

.wizard-container {
    max-width: 600px;
    margin: 0 auto;
}

.consent-section {
    margin-top: 20px;
}

.consent-text {
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 10px;
}
